import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { useAppContext } from 'src/modules/context';
import { isTechUser } from 'src/modules/helpers';

import Logo from 'src/components/Logo';

import styles from './Sidebar.module.scss';

interface Props {
  onLogout: () => void;
}

function Sidebar({ onLogout }: Props) {
  const {
    setState,
    state: { isMobile, ui, user },
  } = useAppContext();

  const handleClickChangePassword = () => {
    setState({ modal: { isActive: true, type: 'change-password' }, ui: { sidebarVisible: false } });
  };

  const handleClickItem = () => {
    if (isMobile && ui.sidebarVisible) {
      setState({ ui: { sidebarVisible: false } });
    }
  };

  return (
    <div
      className={[
        styles.sidebar,
        isMobile && styles.sidebarMobile,
        ui.sidebarVisible && styles.sidebarMobileVisible,
      ]
        .filter(Boolean)
        .join(' ')}
    >
      <Nav>
        {!isMobile && (
          <LinkContainer onClick={handleClickItem} to="/">
            <Logo width="100%" />
          </LinkContainer>
        )}

        <ul
          className={[styles.sidebarMenu, isMobile && styles.sidebarMenuMobile]
            .filter(Boolean)
            .join(' ')}
        >
          <li>
            <LinkContainer onClick={handleClickItem} to="/">
              <Nav.Link>
                <i className="fas fa-home" /> Início
              </Nav.Link>
            </LinkContainer>
          </li>

          <li>
            <a
              className="active nav-link"
              href="https://api.whatsapp.com/send/?phone=5571991900271&text=Ol%C3%A1+INTERA%2C+eu+tenho+uma+duvida%21&app_absent=0"
              onClick={handleClickItem}
              rel="noreferrer"
              target="_blank"
            >
              <i className="far fa-comment-alt" /> Fale com a Intera
            </a>
          </li>

          {isTechUser(user) && (
            <li>
              <LinkContainer onClick={handleClickItem} to="/services">
                <Nav.Link>
                  <i className="fas fa-tools" /> Services
                </Nav.Link>
              </LinkContainer>
            </li>
          )}
        </ul>

        <ul className={styles.sidebarMenu}>
          <li>
            <Nav.Link onClick={handleClickChangePassword}>
              <i className="fas fa-cog" /> Trocar senha
            </Nav.Link>
          </li>
          <li>
            <Nav.Link onClick={onLogout}>
              <i className="fas fa-power-off" /> Sair
            </Nav.Link>
          </li>
        </ul>
      </Nav>
    </div>
  );
}

export default Sidebar;
