import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AnyObject } from '@by-intera/types';

import styles from './NonIdealState.module.scss';

interface Props {
  children?: React.ReactNode;
  description?: React.ReactNode;
  showLink?: boolean;
  title?: React.ReactNode;
  type?: 'companies' | 'contracts' | 'error' | 'leads' | 'notFound' | 'positions';
}

function NonIdealState(props: Props) {
  const { children, description, showLink, title, type } = props;
  const output: AnyObject = {};
  let iconSize = 64;

  const link = (
    <a
      href="https://api.whatsapp.com/send/?phone=5571991900271&text=Ol%C3%A1+INTERA%2C+eu+tenho+uma+duvida%21&app_absent=0"
      rel="noreferrer"
      target="_blank"
    >
      <Button>Entre em contato</Button>
    </a>
  );

  switch (type) {
    case 'companies': {
      output.icon = 'building';
      output.description = (
        <p>
          Aqui ficarão visíveis as suas empresas.
          <br />
          No momento, nenhuma empresa foi cadastrada.
        </p>
      );
      output.content = link;

      break;
    }
    case 'contracts': {
      output.icon = 'handshake';
      output.description = (
        <p>
          Aqui ficarão visíveis os seus contratos de Hunt Hacking.
          <br />
          No momento, ainda não há um histórico de contratos.
        </p>
      );
      output.content = link;

      break;
    }
    case 'leads': {
      output.icon = 'user-search';
      output.description = (
        <p>
          Aqui ficarão visíveis os leads dessa vaga.
          <br />
          No momento, nenhum lead foi cadastrado.
        </p>
      );

      break;
    }
    case 'positions': {
      output.icon = 'user-announce';
      output.description = (
        <p>
          Aqui ficarão visíveis as suas vagas.
          <br />
          No momento, nenhuma vaga foi iniciada neste contrato.
        </p>
      );

      break;
    }
    case 'notFound': {
      output.icon = '404';
      output.title = 'Página não encontrada';
      iconSize = 128;

      break;
    }

    case 'error':
    default: {
      output.icon = 'warning';
      output.description = (
        <p>
          Ocorreu um erro inesperado.
          <br />
          Tente recarregar a página!
        </p>
      );

      break;
    }
  }

  if (showLink) {
    output.content = (
      <Link to="/">
        <Button size="lg">Voltar para home</Button>
      </Link>
    );
  }

  const hasTitle = !!title || !!output.title;
  const hasDescription = !!description || !!output.description;
  const hasContent = !!output.content || !!children;

  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <img alt="Icon" src={`/icons/${output.icon}.svg`} width={iconSize} />
      </div>
      {hasTitle && <h2 className={styles.title}>{title || output.title}</h2>}
      {hasDescription && <div>{description || output.description}</div>}
      {hasContent && <div className={styles.content}>{children || output.content}</div>}
    </div>
  );
}

export default NonIdealState;
