import React from 'react';

import { formatDateLocale } from 'src/modules/formatters';

import { Cycle } from 'src/types';

import Columns from 'src/components/Data/Columns';
import Heading from 'src/components/Heading';

interface Props {
  cycle: Cycle;
}

function CycleHeading({ cycle }: Props) {
  return (
    <Heading
      content={cycle.status.startsWith('FIRST_CICLE') ? 'Primeiro Ciclo' : 'Segundo Ciclo (Atual)'}
      secondary
      style={{ marginTop: 'var(--gutter-lg)' }}
    >
      <Columns>
        <div>
          <h4>Início</h4>
          <span>{formatDateLocale(cycle.startDate)}</span>
        </div>
        <div>
          <h4>Fim</h4>
          <span>{cycle.endDate ? formatDateLocale(cycle.endDate) : 'Ciclo em Andamento'}</span>
        </div>
      </Columns>
    </Heading>
  );
}

export default CycleHeading;
