import { FormEvent, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSetState } from 'react-use';
import { ASYNC_STATUS } from '@by-intera/constants';
import { AnyObject, Constants } from '@by-intera/types';
import { Auth } from 'aws-amplify';

import { useAppContext } from 'src/modules/context';
import { useFormFields } from 'src/modules/hooks';

import FormWrapper from 'src/components/FormWrapper';
import LoaderButton from 'src/components/LoaderButton';
import Portal from 'src/components/Portal';

import styles from './ChangePassword.module.scss';

interface State {
  error: string;
  status: Constants.AsyncStatus;
}

function ChangePassword() {
  const {
    setState,
    state: { modal },
  } = useAppContext();
  const [{ error, status }, setComponentState] = useSetState<State>({
    error: '',
    status: ASYNC_STATUS.IDLE,
  });
  const [fields, handleFieldChange, reset] = useFormFields({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    if (!modal.isActive) {
      setComponentState({
        error: '',
        status: ASYNC_STATUS.IDLE,
      });
      reset();
    }
  }, [modal.isActive, reset, setComponentState]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setComponentState({
      error: '',
      status: ASYNC_STATUS.RUNNING,
    });

    try {
      const currentUser = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(currentUser, fields.oldPassword, fields.password);

      setComponentState({
        status: ASYNC_STATUS.SUCCESS,
      });
    } catch (e: any) {
      setComponentState({
        error: e.message,
        status: ASYNC_STATUS.ERROR,
      });
    }
  };

  const handleClickClose = () => {
    setState({
      modal: {
        isActive: false,
        type: '',
      },
    });
  };

  const isLoading = status === ASYNC_STATUS.RUNNING;
  const isValid =
    fields.oldPassword.length > 0 &&
    fields.password.length > 0 &&
    fields.password === fields.confirmPassword;
  const output: AnyObject = {
    children: (
      <>
        <Form.Group controlId="oldPassword">
          <Form.Control
            onChange={handleFieldChange}
            placeholder="Senha atual"
            type="password"
            value={fields.oldPassword}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Control
            onChange={handleFieldChange}
            placeholder="Nova senha"
            type="password"
            value={fields.password}
          />
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <Form.Control
            onChange={handleFieldChange}
            placeholder="Confirme a nova senha"
            type="password"
            value={fields.confirmPassword}
          />
        </Form.Group>
        <Form.Group>
          <LoaderButton block disabled={isLoading || !isValid} isLoading={isLoading} type="submit">
            Enviar
          </LoaderButton>
        </Form.Group>
      </>
    ),
    description: 'Digite sua senha atual, a nova senha e a confirmação',
  };

  if (isValid && status === ASYNC_STATUS.SUCCESS) {
    output.children = null;
    output.description = (
      <>
        <p>Sua senha foi alterada com sucesso!</p>
        <Button onClick={handleClickClose}>Fechar</Button>
      </>
    );
  }

  return (
    <Portal hideCloseButton isActive={modal.isActive && modal.type === 'change-password'}>
      <button className={styles.closeButton} onClick={handleClickClose} type="button">
        <i className="fas fa-times" />
      </button>
      <FormWrapper
        description={output.description}
        error={error}
        onSubmit={handleSubmit}
        title="Troca de senha"
      >
        {output.children}
      </FormWrapper>
    </Portal>
  );
}

export default ChangePassword;
