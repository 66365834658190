import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ASYNC_STATUS } from '@by-intera/constants';
import { Constants } from '@by-intera/types';
import { Auth } from 'aws-amplify';

import { useAppContext } from 'src/modules/context';

import FormWrapper from 'src/components/FormWrapper';
import LoaderButton from 'src/components/LoaderButton';

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
}

function Login() {
  const { setState } = useAppContext();
  const [error, setError] = useState('');
  const [status, setStatus] = useState<Constants.AsyncStatus>(ASYNC_STATUS.IDLE);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { email, password } = (event.target as HTMLFormElement).elements as FormElements;

    setStatus(ASYNC_STATUS.RUNNING);

    try {
      await Auth.signIn(email.value, password.value);
      setStatus(ASYNC_STATUS.SUCCESS);
      setState({ isAuthenticated: true });
    } catch (e: any) {
      setError(e.message);
      setStatus(ASYNC_STATUS.ERROR);
    }
  };

  const isLoading = status === ASYNC_STATUS.RUNNING;

  return (
    <div className="route-authentication">
      <FormWrapper
        error={error}
        footer={<Link to="/reset">Esqueceu sua senha?</Link>}
        onSubmit={handleSubmit}
        title="Login"
      >
        <Form.Group controlId="email">
          <Form.Control autoFocus placeholder="Seu e-mail" required type="email" />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Control placeholder="Sua senha" required type="password" />
        </Form.Group>
        <Form.Group>
          <LoaderButton block disabled={isLoading} isLoading={isLoading} type="submit">
            Enviar
          </LoaderButton>
        </Form.Group>
      </FormWrapper>
    </div>
  );
}

export default Login;
