import { useCallback, useEffect } from 'react';
import ReactNotification from 'react-notifications-component';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { useAppContext } from 'src/modules/context';
import { parseUser } from 'src/modules/parsers';

import { appState } from 'src/config';

import ChangePassword from 'src/components/ChangePassword';
import Sidebar from 'src/components/Sidebar';
import TopBar from 'src/components/TopBar';

import Routes from 'src/routes';

function App() {
  const {
    setState,
    state: { isAuthenticated, isMobile, ui, user },
  } = useAppContext();

  const load = useCallback(async () => {
    try {
      const session = await Auth.currentSession();

      if (!user?.id) {
        const userData = await Auth.currentUserInfo();
        const groups = session.getAccessToken().payload['cognito:groups'] || [];

        setState({ isAuthenticated: true, user: parseUser(userData, groups) });
      }
    } catch (e) {
      if (e === 'No current user' && isAuthenticated) {
        setState(appState);
      }
    }
  }, [isAuthenticated, setState, user]);

  const updateMediaQuery = useCallback(() => {
    setState({ isMobile: window.innerWidth < 1024 });
  }, [setState]);

  useEffect(() => {
    load();
    window.addEventListener('resize', updateMediaQuery);

    return () => window.removeEventListener('resize', updateMediaQuery);
  }, [load, updateMediaQuery]);

  const handleLogout = async () => {
    await Auth.signOut();
    setState(appState);
  };

  // noinspection Stylelint
  return (
    <Router>
      <div
        style={{
          /* stylelint-disable */
          backgroundColor: isAuthenticated ? 'var(--color-white)' : 'var(--color-dark)',
          minHeight: '100vh',
          maxWidth: '100%',
          padding: 0,
        }}
      >
        <ReactNotification />
        <ChangePassword />
        {isAuthenticated && ui.topBar && <TopBar />}
        {isAuthenticated && ui.sidebar && <Sidebar onLogout={handleLogout} />}

        {isAuthenticated ? (
          <div className={isMobile ? '' : 'panelBody'} style={isMobile ? { paddingTop: 100 } : {}}>
            <Routes />
          </div>
        ) : (
          <Routes />
        )}
      </div>
    </Router>
  );
}

export default App;
