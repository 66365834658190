import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMount } from 'react-use';
import { ASYNC_STATUS } from '@by-intera/constants';
import { AnyObject, Constants } from '@by-intera/types';
import { noop } from '@gilbarbara/helpers';
import { Auth } from 'aws-amplify';

import FormWrapper from 'src/components/FormWrapper';
import Loader from 'src/components/Loader';

function Confirm() {
  const { code, email } = useParams<{ code: string; email: string }>();
  const [status, setStatus] = useState<Constants.AsyncStatus>(ASYNC_STATUS.IDLE);

  const load = async () => {
    try {
      setStatus(ASYNC_STATUS.RUNNING);
      await Auth.confirmSignUp(email, code);
      setStatus(ASYNC_STATUS.SUCCESS);
    } catch (e) {
      setStatus(ASYNC_STATUS.ERROR);
    }
  };

  useMount(load);

  const output: AnyObject = {
    content: <Loader height="10px" width="100%" />,
  };

  if (status === ASYNC_STATUS.SUCCESS) {
    output.content = <strong>Seu e-mail foi confirmado com sucesso.</strong>;
    output.link = <Link to="/login">Clique aqui para entrar.</Link>;
  } else if (status === ASYNC_STATUS.ERROR) {
    output.content = (
      <strong>
        Falha ao confirmar seu e-mail.
        <br />
        Confira se a URL está correta.
      </strong>
    );
    output.link = (
      <a
        href="https://api.whatsapp.com/send/?phone=5571991900271&text=Ol%C3%A1+INTERA%2C+eu+tenho+uma+duvida%21&app_absent=0"
        rel="noreferrer"
        target="_blank"
      >
        Entre em contato
      </a>
    );
  }

  return (
    <div className="route-authentication">
      <FormWrapper footer={output.link} onSubmit={noop} title="Confirmação">
        {output.content}
      </FormWrapper>
    </div>
  );
}

export default Confirm;
