import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAppContext } from 'src/modules/context';

function querystring(input: string, url = window.location.href) {
  const name = input.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`, 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function RoutePublic({ children, ...rest }: React.PropsWithChildren<any>) {
  const {
    state: { isAuthenticated },
  } = useAppContext();
  const redirect = querystring('redirect');

  return (
    <Route {...rest}>
      {!isAuthenticated ? (
        children
      ) : (
        <Redirect to={redirect === '' || redirect === null ? '/' : redirect} />
      )}
    </Route>
  );
}

export default RoutePublic;
