import { Redirect, Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import RoutePrivate from './components/RoutePrivate';
import RoutePublic from './components/RoutePublic';
import Application from './containers/Application';
import Applications from './containers/Applications';
import Confirm from './containers/Confirm';
import Credits from './containers/Credits';
import Home from './containers/Home';
import Login from './containers/Login';
import NotFound from './containers/NotFound';
import Positions from './containers/Positions';
import Recover from './containers/Recover';
import Reset from './containers/Reset';
import Signup from './containers/Signup';

const Services = loadable(() => import(/* webpackChunkName: "services" */ './containers/Services'));

export default function Routes() {
  return (
    <Switch>
      <RoutePrivate exact path="/">
        <Home />
      </RoutePrivate>
      <Redirect from="/contracts/:companyId" to="/credits/:companyId" />
      <RoutePrivate exact path="/credits/:companyId">
        <Credits />
      </RoutePrivate>
      <RoutePrivate exact path="/positions">
        <Positions />
      </RoutePrivate>
      <RoutePrivate exact path="/positions/:companyId/:creditsId">
        <Positions />
      </RoutePrivate>
      <Redirect from="/applications/position/:id" to="/applications/:id" />
      <RoutePrivate exact path="/applications/:id">
        <Applications />
      </RoutePrivate>
      <Redirect from="/application/:id" to="/talent/:id" />
      <RoutePrivate exact path="/talent/:id">
        <Application />
      </RoutePrivate>
      <RoutePrivate exact path="/services">
        <Services />
      </RoutePrivate>
      <RoutePublic exact path="/login">
        <Login />
      </RoutePublic>
      <RoutePublic exact path="/reset">
        <Reset />
      </RoutePublic>
      <RoutePublic exact path="/verify/:email/:code">
        <Recover />
      </RoutePublic>
      <RoutePublic exact path="/confirm/:email/:code">
        <Confirm />
      </RoutePublic>
      <RoutePublic exact path="/signup">
        <Signup />
      </RoutePublic>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
