import React from 'react';
import { Link } from 'react-router-dom';

import Columns from './Columns';
import styles from './Data.module.scss';

export interface ItemData {
  columns: JSX.Element[];
  heading?: JSX.Element;
  link: string;
}

interface Props {
  item: ItemData;
  style: React.CSSProperties;
}

function DataItem({ item, style }: Props) {
  return (
    <Link className={styles.item} style={style} to={item.link}>
      {item.heading && <div className={styles.itemHeading}>{item.heading}</div>}
      <Columns>{item.columns}</Columns>
    </Link>
  );
}

export default DataItem;
