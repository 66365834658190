import React, { useState } from 'react';

import styles from './ReadMore.module.scss';

interface Props {
  children: string;
  limit?: number;
  skip: boolean;
}

function ReadMore({ children, limit = 150, skip }: Props) {
  const text = children;
  const [isTrimmed, setTrimmed] = useState(text?.length > limit);

  const handleClick = () => {
    setTrimmed(s => !s);
  };

  if (!text) {
    return null;
  }

  const parsedText = text.split('\n').map((d, i) => <p key={i}>{d}</p>);

  if (skip) {
    return <div className="text">{parsedText}</div>;
  }

  return (
    <div className="text">
      {isTrimmed ? text.substring(0, limit) : parsedText}
      {text.length > limit && (
        <span className={styles.content} onClick={handleClick} role="presentation">
          {isTrimmed ? '... Leia mais' : 'Minimizar texto'}
        </span>
      )}
    </div>
  );
}

export default ReadMore;
