import { useMount, useSetState } from 'react-use';
import { ASYNC_STATUS } from '@by-intera/constants';
import { AnyObject, Constants, Intera } from '@by-intera/types';

import { useAppContext } from 'src/modules/context';
import { getCompanies } from 'src/modules/requests';

import { appState } from 'src/config';

import Container from 'src/components/Container';
import Data from 'src/components/Data';
import Heading from 'src/components/Heading';
import Loader from 'src/components/Loader';
import NonIdealState from 'src/components/NonIdealState';
import Screen from 'src/components/Screen';
import StatusIndicator from 'src/components/StatusIndicator';

import styles from './Home.module.scss';

interface State {
  active: Intera.Company[];
  inactive: Intera.Company[];
  status: Constants.AsyncStatus;
}

function buildData(companies: Intera.Company[], isActive = false) {
  return companies.map(company => {
    return {
      link: `/credits/${company.id}`,
      columns: [
        <div key="status" data-type="indicator">
          <StatusIndicator isActive={isActive} />
        </div>,
        <div key="company" data-type="company">
          {company.img ? (
            <img alt={company.name} className={styles.logo} src={company.img} />
          ) : (
            company.name
          )}
        </div>,
      ],
    };
  });
}

function Home() {
  const { setState } = useAppContext();
  const [{ active, inactive, status }, setComponentState] = useSetState<State>({
    active: [],
    inactive: [],
    status: ASYNC_STATUS.IDLE,
  });

  const load = async () => {
    setState({
      company: appState.company,
      creditsGroup: appState.creditsGroup,
    });

    try {
      setComponentState({ status: ASYNC_STATUS.RUNNING });

      const companies = await getCompanies();

      setComponentState({
        active: companies.filter(company => company.status === 'ATIVO'),
        inactive: companies.filter(company => company.status === 'INATIVO'),
        status: ASYNC_STATUS.SUCCESS,
      });
    } catch (e) {
      setComponentState({ status: ASYNC_STATUS.ERROR });
    }
  };

  useMount(load);

  const output: AnyObject = {
    main: <Loader origin="route" />,
  };

  if (active.length) {
    output.active = (
      <>
        <Heading content="Empresas Ativas" />
        <Data data={buildData(active, true)} minHeight={85} />
      </>
    );
  }

  if (inactive.length) {
    output.inactive = (
      <>
        <Heading content="Empresas Inativas" secondary style={{ marginTop: 32 }} />
        <Data data={buildData(inactive)} />
      </>
    );
  }

  if (([ASYNC_STATUS.SUCCESS, ASYNC_STATUS.ERROR] as Constants.AsyncStatus[]).includes(status)) {
    if (!active.length && !inactive.length) {
      output.main = <NonIdealState type="companies" />;
    } else {
      output.main = (
        <>
          {output.active}
          {output.inactive}
        </>
      );
    }
  }

  return (
    <Screen>
      <Container>{output.main}</Container>
    </Screen>
  );
}

export default Home;
