import React from 'react';

import styles from './Data.module.scss';
import Item, { ItemData } from './Item';

interface Props extends React.CSSProperties {
  data: ItemData[];
}

function Data({ data, ...rest }: Props) {
  return (
    <div className={styles.wrapper}>
      {data.map((d, i) => (
        <Item key={`${d.link}-${i}`} item={d} style={rest} />
      ))}
    </div>
  );
}

export default Data;
