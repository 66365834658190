import styles from './StatusIndicator.module.scss';

interface Props {
  isActive?: boolean;
  isRejected?: boolean;
}

function StatusIndicator({ isActive, isRejected }: Props) {
  return (
    <div
      className={[
        styles.wrapper,
        isRejected && !isActive && styles.reject,
        isActive && styles.active,
      ]
        .filter(Boolean)
        .join(' ')}
    />
  );
}

export default StatusIndicator;
