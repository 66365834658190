import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { FaCheck, FaChevronCircleLeft, FaComment } from 'react-icons/fa';
import Markdown from 'react-markdown';
import { store } from 'react-notifications-component';
import { Star } from 'react-star';
import { useSetState } from 'react-use';
import { ASYNC_STATUS } from '@by-intera/constants';
import { AnyObject, Constants } from '@by-intera/types';
import is from 'is-lite';

import { updateApplication } from 'src/modules/requests';

import Loader from 'src/components/Loader';
import LoaderButton from 'src/components/LoaderButton';

import styles from './Application.module.scss';

interface Props {
  comment?: string;
  id: string;
  isMobile?: boolean;
  rating?: number;
}

interface State {
  currentComment: string;
  currentRating: number;
  previousComment: string;
  previousRating: number;
  showForm: boolean;
  status: Constants.AsyncStatus;
}

function ApplicationFeedback({
  comment = '',
  id,
  isMobile = false,
  rating = 0,
}: Props): JSX.Element {
  const [
    { currentComment, currentRating, previousComment, previousRating, showForm, status },
    setState,
  ] = useSetState<State>({
    currentComment: comment,
    currentRating: rating,
    previousComment: comment,
    previousRating: rating,
    showForm: false,
    status: ASYNC_STATUS.IDLE,
  });

  const showError = () => {
    store.addNotification({
      title: 'Erro',
      message: 'Não foi possível salvar os dados. Tente novamente mais tarde.',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 10000,
        onScreen: true,
      },
    });
  };

  const toggleForm = () => {
    setState(s => ({ showForm: !s.showForm }));
  };

  const handleChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { target } = e;

    setState({ currentComment: target.value });
  };

  const handleClickRating = async (value?: number) => {
    if (is.number(value)) {
      setState({ currentRating: value, status: ASYNC_STATUS.RUNNING });

      try {
        await updateApplication(id, { ratingClient: value });
        setState({ previousRating: value, status: ASYNC_STATUS.SUCCESS });
      } catch {
        setState({ currentRating: previousRating, status: ASYNC_STATUS.ERROR });
        showError();
      }
    }
  };

  const handleClickCancel = () => {
    setState({ currentComment: previousComment });
    toggleForm();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setState({ status: ASYNC_STATUS.RUNNING });

    try {
      await updateApplication(id, { feedbackClient: currentComment });
      setState({ previousComment: currentComment, showForm: false, status: ASYNC_STATUS.SUCCESS });
    } catch {
      setState({ status: ASYNC_STATUS.ERROR });
      showError();
    }
  };

  const isRunning = status === ASYNC_STATUS.RUNNING;
  const isDisabled = isRunning || previousComment === currentComment;

  const output: AnyObject = {
    comment: (
      <Button onClick={toggleForm} size="sm">
        {isMobile ? <FaComment /> : 'Deixar um comentário'}
      </Button>
    ),
  };

  if (currentComment) {
    output.comment = (
      <div className={styles.comment}>
        <Markdown>{currentComment}</Markdown>
        <button disabled={isRunning} onClick={toggleForm} type="button">
          Editar comentário
        </button>
      </div>
    );
  }

  if (showForm) {
    output.commentForm = isMobile ? (
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <button
            onClick={handleClickCancel}
            style={{ display: 'inline', marginTop: '-100px !important' }}
            type="button"
          >
            <FaChevronCircleLeft />
          </button>
          <Form.Control
            as="textarea"
            name="comment"
            onChange={handleChangeComment}
            rows={1}
            style={{
              display: 'inline',
              maxWidth: '80%',
              border: '0',
              borderRadius: '15px',
              marginLeft: '10px',
              marginRight: '10px',
            }}
            value={currentComment}
          />
          <LoaderButton
            block
            disabled={isDisabled}
            isLoading={previousComment !== currentComment && isRunning}
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              display: 'inline',
              marginTop: '-20px',
            }}
            type="submit"
          >
            <FaCheck />
          </LoaderButton>
        </Form.Group>
      </Form>
    ) : (
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Control
            as="textarea"
            name="comment"
            onChange={handleChangeComment}
            rows={4}
            value={currentComment}
          />
        </Form.Group>
        <Form.Group>
          <LoaderButton
            block
            disabled={isDisabled}
            isLoading={previousComment !== currentComment && isRunning}
            type="submit"
          >
            Enviar
          </LoaderButton>
          <button onClick={handleClickCancel} type="button">
            cancelar
          </button>
        </Form.Group>
      </Form>
    );
  }

  return !isMobile ? (
    <div className={styles.client}>
      <h3>Feedback</h3>

      <div className={styles.ratings}>
        <Star
          key={currentRating}
          defaultValue={currentRating}
          onChange={handleClickRating}
          readOnly={isRunning}
        />
        {previousRating !== currentRating && isRunning && <Loader type="spin" />}
      </div>

      {output.comment}
      {output.commentForm}
    </div>
  ) : (
    <div className={styles.ratingsMobile}>
      <p>Como você avalia este talento?</p>

      <div style={{ display: 'block', textAlign: 'center' }}>
        <Star
          key={currentRating}
          defaultValue={currentRating}
          onChange={handleClickRating}
          readOnly={isRunning}
        />
        {previousRating !== currentRating && isRunning && <Loader type="spin" />}
      </div>

      <div>{output.commentForm}</div>
    </div>
  );
}

export default ApplicationFeedback;
