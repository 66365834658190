import { APPLICATION_STATUS, JOB_STATUS } from '@by-intera/constants';
import { Constants, Intera } from '@by-intera/types';
import { sortByPrimitive } from '@gilbarbara/helpers';

import { Application, ApplicationsCycles, Cycle, User } from 'src/types';

export function parseApplication(app: Intera.Application): Application {
  return {
    becameLeadAt: app.application.becameLeadAt ? new Date(app.application.becameLeadAt) : null,
    cycle: app.application.cycle || 1,
    email: decodeURIComponent(app.candidate.email),
    firstName: app.candidate.firstName,
    id: app.id,
    idBackoffice: app.application.idBackoffice || null,
    isRejected: parseApplicationRejectStatus(app.application.status),
    lastName: app.candidate.lastName,
    perfil: app.candidate.linkedinProfileUrl,
    phone: app.candidate.phone,
    status: app.application.status,
  };
}

export function parseApplicationStatus(status: Constants.ApplicationStatus) {
  const appStatuses = {
    [APPLICATION_STATUS.APPLIED]: 'Inscrito',
    [APPLICATION_STATUS.APPROVED]: 'Aprovado',
    [APPLICATION_STATUS.DISMISSED]: 'Finalizado',
    [APPLICATION_STATUS.EVALUATING]: 'Avaliação',
    [APPLICATION_STATUS.HIRED]: 'Contratado',
    [APPLICATION_STATUS.IGNORED]: 'Ignorar',
    [APPLICATION_STATUS.INITIALIZING]: 'Inicializando',
    [APPLICATION_STATUS.INTERVIEWING]: 'Entrevista',
    [APPLICATION_STATUS.NOT_APPROVED]: 'Reprovado',
    [APPLICATION_STATUS.OFFER_MADE]: 'Aguardando Oferta',
    [APPLICATION_STATUS.OFFER_REFUSED]: 'Oferta Recusada',
    [APPLICATION_STATUS.ON_HOLD]: 'Parado',
    [APPLICATION_STATUS.PRELEAD_CHALLENGE]: 'Aguardando desafio',
    [APPLICATION_STATUS.PROJECT_FINISHED]: 'Projeto finalizado',
    [APPLICATION_STATUS.SCHEDULED_INTERVIEW]: 'Agendar entrevista',
    [APPLICATION_STATUS.STANDBY]: 'Pausado',
    [APPLICATION_STATUS.SWITCH_JOB]: 'Troca de vaga',
    [APPLICATION_STATUS.TERMINATED]: 'Encerrado',
    [APPLICATION_STATUS.UNKNOWN]: 'Desconhecido',
    [APPLICATION_STATUS.VALIDATING]: 'Validação',
    [APPLICATION_STATUS.WAITING_INTERVIEW]: 'Aguardando Entrevista',
    [APPLICATION_STATUS.WITHDREW]: 'Desistiu',
  };

  return appStatuses[status];
}

export function parseApplicationRejectStatus(status?: Constants.ApplicationStatus): boolean {
  if (!status) {
    return true;
  }

  return (
    [
      APPLICATION_STATUS.WITHDREW,
      APPLICATION_STATUS.OFFER_REFUSED,
      APPLICATION_STATUS.DISMISSED,
      APPLICATION_STATUS.NOT_APPROVED,
      APPLICATION_STATUS.PROJECT_FINISHED,
    ] as Constants.ApplicationStatus[]
  ).includes(status);
}

export function parseJobIntoCycles(job: Intera.Job): Cycle[] {
  const cycles: Cycle[] = [];
  const { history = [] } = job ?? {};

  const sortedHistory = [...history].sort(
    (a, b) => parseFloat(a.changedAt) - parseFloat(b.changedAt),
  );
  const hasInternalSecondCycle = !!sortedHistory.find(d => d.value === JOB_STATUS.SECOND_CICLE);

  Object.keys(sortedHistory).forEach((index: string) => {
    const indexNum = Number(index);
    const item = sortedHistory[indexNum];
    const nextItem = sortedHistory[indexNum + 1];
    const status = item.value as Constants.JobStatus;

    const internalCycles = [
      JOB_STATUS.FIRST_CICLE,
      JOB_STATUS.SECOND_CICLE,
    ] as Constants.JobStatus[];
    const clientCycles = [
      JOB_STATUS.FIRST_CICLE_CLIENT,
      JOB_STATUS.SECOND_CICLE_CLIENT,
    ] as Constants.JobStatus[];
    const includeSecondCycleClient =
      status === JOB_STATUS.SECOND_CICLE_CLIENT && !hasInternalSecondCycle;

    if (item.value && (internalCycles.includes(status) || includeSecondCycleClient)) {
      let endDate = '';

      if (nextItem) {
        if (nextItem.value && !clientCycles.includes(nextItem.value as Constants.JobStatus)) {
          endDate = nextItem.changedAt;
        } else if (sortedHistory[indexNum + 2]) {
          endDate = sortedHistory[indexNum + 2].changedAt;
        }
      }

      cycles.push({
        credits: job.credits || 'Não Configurado',
        endDate,
        name: item.name || '',
        startDate: item.changedAt,
        status,
      });
    }
  });

  return [...cycles].reverse();
}

export function parseJobStatus(status: keyof Intera.JobCounters) {
  const statuses = {
    FIRST_CICLE: '1º ciclo',
    FIRST_CICLE_CLIENT: '1º ciclo com o cliente',
    FINISHED: 'Finalizado',
    PAUSED: 'Pausado',
    SECOND_CICLE: '2º ciclo',
    SECOND_CICLE_CLIENT: '2º ciclo com o cliente',
    SETUP: 'Iniciado',
  };

  return statuses[status] || status;
}

export function parseLeads(applications: Intera.Application[]) {
  const { firstCycle, secondCycle } = applications
    .filter(
      d =>
        !(
          [
            APPLICATION_STATUS.SWITCH_JOB,
            APPLICATION_STATUS.UNKNOWN,
            APPLICATION_STATUS.IGNORED,
          ] as Constants.ApplicationStatus[]
        ).includes(d.application.status),
    )
    .reduce(
      (acc, d) => {
        if (d.application.cycle === 1) {
          acc.firstCycle.push(parseApplication(d));
        } else {
          acc.secondCycle.push(parseApplication(d));
        }

        return acc;
      },
      {
        firstCycle: [],
        secondCycle: [],
      } as ApplicationsCycles,
    );

  return {
    firstCycle: firstCycle.sort(sortByPrimitive<boolean>('isRejected')),
    secondCycle: secondCycle.sort(sortByPrimitive<boolean>('isRejected')),
  };
}

export function parseUser(user: Intera.CognitoUser, groups: string[]): User {
  const {
    attributes = {
      'custom:companyIds': '',
      email: '',
      name: '',
    },
    id = '',
    username = '',
  } = user || {};

  return {
    companyIds: attributes['custom:companyIds']?.split(',').filter(Boolean) || [],
    email: attributes.email,
    groups,
    id,
    name: attributes.name,
    username,
  };
}
