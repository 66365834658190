import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ASYNC_STATUS } from '@by-intera/constants';
import { AnyObject, Constants } from '@by-intera/types';
import { Auth } from 'aws-amplify';

import FormWrapper from 'src/components/FormWrapper';
import LoaderButton from 'src/components/LoaderButton';

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
}

function Reset() {
  const [error, setError] = useState('');
  const [status, setStatus] = useState<Constants.AsyncStatus>(ASYNC_STATUS.IDLE);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { value } = ((event.target as HTMLFormElement).elements as FormElements).email;

    setStatus(ASYNC_STATUS.RUNNING);

    try {
      await Auth.forgotPassword(value);
      setStatus(ASYNC_STATUS.SUCCESS);
    } catch (e: any) {
      setError(e.message);
      setStatus(ASYNC_STATUS.ERROR);
    }
  };

  const isLoading = status === ASYNC_STATUS.RUNNING;

  const output: AnyObject = {
    title: 'Recuperação de senha',
    description: 'Digite seu e-mail que enviaremos um link para você cadastrar uma nova senha:',
    children: (
      <>
        <Form.Group controlId="email">
          <Form.Control
            autoFocus
            disabled={isLoading}
            placeholder="Seu e-email"
            required
            type="email"
          />
        </Form.Group>

        <Form.Group>
          <LoaderButton block disabled={isLoading} isLoading={isLoading} type="submit">
            Enviar
          </LoaderButton>
        </Form.Group>
      </>
    ),
    footer: <Link to="/login">{'<'} Voltar para o login</Link>,
  };

  if (status === ASYNC_STATUS.SUCCESS) {
    output.children = null;
    output.description =
      'As instruções para recuperação de senha chegarão em seu email a qualquer momento ;)';
  }

  return (
    <div className="route-authentication">
      <FormWrapper
        description={output.description}
        error={error}
        footer={output.footer}
        onSubmit={handleSubmit}
        title={output.title}
      >
        {output.children}
      </FormWrapper>
    </div>
  );
}

export default Reset;
