import React from 'react';
import { BsArrowRepeat } from 'react-icons/bs';

import styles from './Loader.module.scss';

interface Props extends React.CSSProperties {
  origin?: 'route';
  type?: 'background' | 'spin';
}

function Loader({ origin, type, ...rest }: Props) {
  let style = rest;

  if (type === 'spin') {
    return <BsArrowRepeat className="icon-spin icon-spin-r" />;
  }

  if (origin === 'route') {
    style = {
      ...rest,
      display: 'block',
      height: 16,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 30,
      width: 290,
    };
  }

  return <div className={styles.background} style={style} />;
}

export default Loader;
