import { MouseEvent, useEffect, useRef, useState } from 'react';

import styles from './CopyNotification.module.scss';

interface Props {
  copyText?: string;
  textButton?: string;
  textMessage?: string;
}

function CopyNotification({
  copyText = 'Nada foi copiado',
  textButton = 'Copiar ',
  textMessage = 'Copiado!',
}: Props) {
  const [isMounted, setMounted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const timeout = useRef<number>();

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (showAlert) {
      timeout.current = window.setTimeout(() => {
        if (isMounted) {
          setShowAlert(false);
        }
      }, 2000);
    }
  }, [isMounted, showAlert]);

  const handleClickAlert = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setShowAlert(false);
  };

  const handleClickButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    clearTimeout(timeout.current);
    setShowAlert(s => !s);
    navigator.clipboard.writeText(copyText);
  };

  return (
    <div className={styles.wrapper}>
      <button className={styles.button} onClick={handleClickButton} type="button">
        {textButton}
        <i className="fas fa-link" />
      </button>
      {showAlert && (
        <div className={styles.alert} onClick={handleClickAlert} role="presentation">
          {textMessage}
        </div>
      )}
    </div>
  );
}

export default CopyNotification;
