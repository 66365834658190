import React from 'react';

import styles from './Screen.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

function Screen({ children, className }: Props): JSX.Element {
  const classes = [styles.wrapper, className].filter(Boolean).join(' ');

  return <div className={classes}>{children}</div>;
}

export default Screen;
