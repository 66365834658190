import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { ASYNC_STATUS } from '@by-intera/constants';
import { AnyObject, Constants } from '@by-intera/types';
import { Auth } from 'aws-amplify';

import { useAppContext } from 'src/modules/context';
import { useFormFields } from 'src/modules/hooks';

import FormWrapper from 'src/components/FormWrapper';
import LoaderButton from 'src/components/LoaderButton';

interface FormElements extends HTMLFormControlsCollection {
  confirmPassword: HTMLInputElement;
  password: HTMLInputElement;
}

function Recover() {
  const { setState } = useAppContext();
  const { code, email } = useParams<{ code: string; email: string }>();
  const [error, setError] = useState('');
  const [status, setStatus] = useState<Constants.AsyncStatus>(ASYNC_STATUS.IDLE);
  const [fields, handleChange] = useFormFields({ password: '', confirmPassword: '' });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { value } = ((event.target as HTMLFormElement).elements as FormElements).password;

    setStatus(ASYNC_STATUS.RUNNING);

    try {
      await Auth.forgotPasswordSubmit(email, code, value);
      await Auth.signIn(email, value);

      setStatus(ASYNC_STATUS.SUCCESS);
      setState({ isAuthenticated: true });
    } catch (e: any) {
      let err = e.message;

      if (err.startsWith('Invalid code provided,')) {
        err = 'Código inválido! Tente iniciar o fluxo de recuperação de senha novamente.';
      } else if (err.startsWith('Attempt limit exceeded,')) {
        err = 'Limite de tentativas excedido. Tente novamente mais tarde.';
      }

      setError(err);
      setStatus(ASYNC_STATUS.ERROR);
    }
  };

  const isLoading = status === ASYNC_STATUS.RUNNING;
  const isValid = fields.password.length > 0 && fields.password === fields.confirmPassword;

  const output: AnyObject = {
    title: 'Redefinir senha',
    description: 'Digite a nova senha e a confirmação para completar a alteração:',
    children: (
      <>
        <Form.Group controlId="password">
          <Form.Control
            onChange={handleChange}
            placeholder="Nova senha"
            type="password"
            value={fields.password}
          />
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <Form.Control
            onChange={handleChange}
            placeholder="Confirmação da senha"
            type="password"
            value={fields.confirmPassword}
          />
        </Form.Group>

        <Form.Group>
          <LoaderButton block disabled={isLoading || !isValid} isLoading={isLoading} type="submit">
            Enviar
          </LoaderButton>
        </Form.Group>
      </>
    ),
    footer: <Link to="/login">{'<'} Voltar para o login</Link>,
  };

  return (
    <div className="route-authentication">
      <FormWrapper
        description={output.description}
        error={error}
        footer={output.footer}
        onSubmit={handleSubmit}
        title={output.title}
      >
        {output.children}
      </FormWrapper>
    </div>
  );
}

export default Recover;
