import './styles/index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Amplify } from 'aws-amplify';

import { AppProvider } from 'src/modules/context';

import Index from 'src/components/ErrorFallback';

import App from './App';
import config from './config';
import reportWebVitals from './reportWebVitals';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'byintera-svcs',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: 'backoffice',
        endpoint: 'https://backoffice.byintera.com/api',
        region: null,
      },
    ],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={Index}
      onReset={() => {
        // eslint-disable-next-line no-console
        console.log('ErrorBoundary:reset');
      }}
    >
      <AppProvider>
        <App />
      </AppProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
