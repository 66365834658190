import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAppContext } from 'src/modules/context';

import Loader from 'src/components/Loader';

import styles from './Breadcrumbs.module.scss';

interface Breadcrumb {
  icon: string;
  link: string;
  text: ReactNode;
}

function Breadcrumbs() {
  const {
    state: { candidate, company, creditsGroup, position },
  } = useAppContext();
  const { pathname } = useLocation();

  const [base, ...params] = pathname.split('/').slice(1).filter(Boolean);
  const loading = <Loader height={10} width={80} />;

  const breadcrumbs: Breadcrumb[] = [
    {
      icon: 'fas fa-home',
      text: 'Início',
      link: '/',
    },
  ];

  if (['credits', 'positions', 'applications', 'talent'].includes(base)) {
    if (base === 'positions' && !params.length) {
      // Since there are no company in the pathname, we shouldn't add this
    } else {
      breadcrumbs.push({
        icon: 'fas fa-copy',
        text: 'Contratos',
        link: `/credits/${company.id}`,
      });
    }
  }

  if (['positions', 'applications', 'talent'].includes(base)) {
    breadcrumbs.push({
      icon: 'fas fa-file',
      text: creditsGroup.name || loading,
      link:
        company.id && creditsGroup.id
          ? `/positions/${company.id}/${creditsGroup.id}`
          : '/positions',
    });
  }

  if (['applications', 'talent'].includes(base)) {
    breadcrumbs.push({
      icon: 'fas fa-users',
      text: position.name || loading,
      link: `/applications/${position.id}`,
    });
  }

  if (['talent'].includes(base)) {
    breadcrumbs.push({
      icon: 'fas fa-user',
      text: candidate.name || loading,
      link: `/candidate/${candidate.id}`,
    });
  }

  return (
    <div className={styles.breadcrumbs}>
      <ul>
        {breadcrumbs.map((item, index) => {
          return (
            <li
              key={item.link}
              className={index === breadcrumbs.length - 1 ? styles.active : undefined}
            >
              <Link to={item.link}>
                <i className={item.icon} /> {item.text}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Breadcrumbs;
