import { useCallback, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';

import { useAppContext } from 'src/modules/context';
import { getCompany } from 'src/modules/requests';

import Breadcrumbs from 'src/components/Breadcrumbs';

import styles from './TopBar.module.scss';

function TopBar() {
  const {
    setState,
    state: { company, isMobile, ui, user },
  } = useAppContext();

  const load = useCallback(async () => {
    try {
      if (!company.id) {
        return;
      }

      const { img, name } = await getCompany(company.id);

      setState({
        company: {
          id: company.id,
          logo: img || '',
          name,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [company.id, setState]);

  useEffect(() => {
    load();
  }, [load]);

  const handleClickMenu = () => {
    setState({ ui: { sidebarVisible: !ui.sidebarVisible } });
  };

  return isMobile ? (
    <div className={styles.wrapperMobile}>
      <div className={styles.menu}>
        <FaBars onClick={handleClickMenu} />
      </div>

      <p className={styles.welcome}>
        Bem-vindo, {isMobile} <b>{user?.name}</b>
      </p>

      {company.logo && (
        <div className={styles.logo}>
          <img alt={company.name} src={company.logo} />
        </div>
      )}
    </div>
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <p>
            Seja bem-vindo(a) novamente
            {user?.name ? `, ${user.name}` : ''}!
          </p>
          <p>
            Enquanto você esteve fora, estávamos construindo um time incrível para você. Vamos lá?
          </p>
        </div>
        {!!company.name && (
          <div className={styles.brand}>
            {company.logo ? (
              <img alt={company.name} className="img-fluid" src={company.logo} />
            ) : (
              company.name
            )}
          </div>
        )}
      </div>

      <Breadcrumbs />
    </div>
  );
}

export default TopBar;
