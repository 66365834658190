import React from 'react';
import { AnyObject } from '@by-intera/types';
import is from 'is-lite';

import styles from './Heading.module.scss';

interface Props {
  children?: React.ReactNode;
  content: React.ReactNode;
  secondary?: boolean;
  style?: React.CSSProperties;
  withBorder?: boolean;
}

function Heading({
  children,
  content,
  secondary = false,
  withBorder,
  ...rest
}: Props): JSX.Element {
  const output: AnyObject = {};

  if (secondary) {
    output.content = is.string(content) ? <h2>{content}</h2> : content;
  } else {
    output.content = is.string(content) ? <h1>{content}</h1> : content;
  }

  const classes = [
    styles.wrapper,
    secondary && styles.wrapperSecondary,
    withBorder && styles.wrapperWithBorder,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={classes} {...rest}>
      {output.content}
      {children && <div className={styles.children}>{children}</div>}
    </div>
  );
}

export default Heading;
