import { useParams } from 'react-router-dom';
import { useMount, useSetState } from 'react-use';
import { ASYNC_STATUS, CREDITS_GROUP_STATUS } from '@by-intera/constants';
import { AnyObject, Constants, Intera } from '@by-intera/types';
import is from 'is-lite';

import { buildCreditsGroup } from 'src/modules/builders';
import { useAppContext } from 'src/modules/context';
import { getCredits } from 'src/modules/requests';

import { appState } from 'src/config';

import Container from 'src/components/Container';
import Data from 'src/components/Data';
import Heading from 'src/components/Heading';
import Loader from 'src/components/Loader';
import NonIdealState from 'src/components/NonIdealState';
import Screen from 'src/components/Screen';

import Panel from './Panel';

interface State {
  active: Intera.CreditsGroup[];
  credits: Intera.CreditsGroup[];
  error: string;
  finished: Intera.CreditsGroup[];
  status: Constants.AsyncStatus;
}

interface CreditsByStatus {
  active: Intera.CreditsGroup[];
  finished: Intera.CreditsGroup[];
}

function buildData(credits: Intera.CreditsGroup[]) {
  if (!is.array(credits)) {
    return [];
  }

  return credits.map(credit => buildCreditsGroup(credit));
}

function Credits() {
  const { setState } = useAppContext();
  const { companyId } = useParams<{ companyId: string }>();
  const [{ active, credits, error, finished, status }, setComponentState] = useSetState<State>({
    active: [],
    credits: [],
    error: '',
    finished: [],
    status: ASYNC_STATUS.IDLE,
  });

  const load = async () => {
    try {
      setComponentState({ status: ASYNC_STATUS.RUNNING });
      setState({
        candidate: appState.candidate,
        company: {
          id: Number(companyId),
        },
        creditsGroup: appState.creditsGroup,
        position: appState.position,
      });

      const creditsData = await getCredits(companyId);

      const creditsByStatus = creditsData.reduce<CreditsByStatus>(
        (acc, creditsGroupRecord) => {
          if (creditsGroupRecord.creditsGroup.status === CREDITS_GROUP_STATUS.IN_PROGRESS) {
            acc.active.push(creditsGroupRecord.creditsGroup);
          } else {
            acc.finished.push(creditsGroupRecord.creditsGroup);
          }

          return acc;
        },
        {
          active: [],
          finished: [],
        },
      );

      setComponentState({
        credits: creditsData.map(creditsGroupRecord => creditsGroupRecord.creditsGroup),
        status: ASYNC_STATUS.SUCCESS,
        ...creditsByStatus,
      });
    } catch (e: any) {
      let { message } = e;

      if (message.startsWith('Request failed')) {
        message = 'Não conseguimos carregar os contratos.';
      }

      setComponentState({
        error: message,
        status: ASYNC_STATUS.ERROR,
      });
    }
  };

  useMount(load);

  const output: AnyObject = {
    main: <Loader origin="route" />,
  };

  if (status === ASYNC_STATUS.ERROR) {
    output.main = <NonIdealState description={error} showLink type="error" />;
  } else if (status === ASYNC_STATUS.SUCCESS) {
    if (!credits.length) {
      output.main = <NonIdealState type="contracts" />;
    } else {
      output.panel = <Panel credits={credits} />;

      if (active.length) {
        output.active = (
          <>
            <Heading
              content="Contratos Ativos"
              secondary
              style={{ marginTop: 'var(--gutter-lg)' }}
            />
            <Data data={buildData(active)} />
          </>
        );
      }

      if (finished.length) {
        output.finished = (
          <>
            <Heading
              content="Contratos Encerrados"
              secondary
              style={{ marginTop: 'var(--gutter-lg)' }}
            />
            <Data data={buildData(finished)} />
          </>
        );
      }

      output.main = (
        <>
          {output.panel}
          {output.active}
          {output.finished}
        </>
      );
    }
  }

  return (
    <Screen>
      <Container>
        <Heading content="Visão Geral" />
        {output.main}
      </Container>
    </Screen>
  );
}

export default Credits;
