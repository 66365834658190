import React from 'react';

import styles from './Data.module.scss';

interface Props {
  children: JSX.Element[];
  style?: React.CSSProperties;
}

function DataColumns({ children, style }: Props) {
  return (
    <div className={styles.itemColumns} style={style}>
      {children}
    </div>
  );
}

export default DataColumns;
