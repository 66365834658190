import { useParams } from 'react-router-dom';
import { useMount, useSetState } from 'react-use';
import { ASYNC_STATUS } from '@by-intera/constants';
import { AnyObject, Constants, Intera } from '@by-intera/types';
import is from 'is-lite';

import { buildJob } from 'src/modules/builders';
import { useAppContext } from 'src/modules/context';
import { formatDateLocale } from 'src/modules/formatters';
import { parseApplicationStatus, parseJobIntoCycles, parseLeads } from 'src/modules/parsers';
import { getCredits, getJob, getLeads } from 'src/modules/requests';

import { appState } from 'src/config';

import { Application, Cycle } from 'src/types';

import Container from 'src/components/Container';
import CopyNotification from 'src/components/CopyNotification';
import Data from 'src/components/Data';
import Columns from 'src/components/Data/Columns';
import Heading from 'src/components/Heading';
import Loader from 'src/components/Loader';
import NonIdealState from 'src/components/NonIdealState';
import Phone from 'src/components/Phone';
import Screen from 'src/components/Screen';
import StatusIndicator from 'src/components/StatusIndicator';

import styles from './Applications.module.scss';
import CycleHeading from './CycleHeading';

interface State {
  cycleInfos: Cycle[];
  error: string;
  leadsFirstCycle: Application[];
  leadsSecondCycle: Application[];
  position: Intera.Job | null;
  status: Constants.AsyncStatus;
}

function buildData(applications: Application[]) {
  if (!is.array(applications)) {
    return [];
  }

  return applications.map(application => {
    return {
      heading: (
        <div className={styles.applicationHeading}>
          <StatusIndicator isActive={!application.isRejected} isRejected />
          <h3>
            {application.firstName} {application.lastName}
          </h3>
        </div>
      ),
      link: `/talent/${application.id}`,
      columns: [
        <div key="date">
          <h4>Envio</h4>
          {application.becameLeadAt
            ? formatDateLocale(application.becameLeadAt.toISOString())
            : '--'}
        </div>,
        <div key="phase">
          <h4>Fase</h4>
          {parseApplicationStatus(application.status)}
        </div>,
        <div key="phone">
          <h4>Telefone</h4>
          <Phone fallback="Não Informado" phone={application.phone || ''} />
        </div>,
        <div key="link">
          <CopyNotification
            copyText={`${window.location.origin}/talent/${application.id}`}
            textButton="Copiar Link do Perfil"
          />
        </div>,
      ],
    };
  });
}

function Applications() {
  const { setState } = useAppContext();
  const { id } = useParams<{ id: string }>();
  const [
    { cycleInfos, error, leadsFirstCycle, leadsSecondCycle, position, status },
    setComponentState,
  ] = useSetState<State>({
    cycleInfos: [],
    error: '',
    leadsFirstCycle: [],
    leadsSecondCycle: [],
    position: null,
    status: ASYNC_STATUS.IDLE,
  });

  const load = async () => {
    try {
      setState({ candidate: appState.candidate });
      setComponentState({ status: ASYNC_STATUS.RUNNING });

      const job = await getJob(id);
      const creditsId = job?.creditsId;
      const companyId = job?.companyId;
      let creditsGroupName = 'Contrato ativo';

      const [leads, creditsGroup] = await Promise.all([getLeads(id), getCredits(companyId)]);

      creditsGroup.forEach(c => {
        if (c.creditsGroupId === creditsId) {
          creditsGroupName = c.creditsGroup.name || '';
        }
      });

      setState({
        company: {
          id: companyId,
        },
        creditsGroup: {
          id: creditsId,
          name: creditsGroupName,
        },
        position: {
          id: Number(id),
          name: job.role || 'Processo Não Encontrado',
        },
      });

      const cycles = parseJobIntoCycles(job);
      const { firstCycle, secondCycle } = parseLeads(leads);

      setComponentState({
        leadsFirstCycle: firstCycle,
        leadsSecondCycle: secondCycle,
        cycleInfos: cycles,
        position: job,
        status: ASYNC_STATUS.SUCCESS,
      });
    } catch (e: any) {
      setComponentState({ error: e.message, status: ASYNC_STATUS.ERROR });
    }
  };

  useMount(load);

  const { columns, heading } = position ? buildJob(position, true) : ({} as AnyObject);
  const output: AnyObject = {
    main: <Loader origin="route" />,
  };

  if (status === ASYNC_STATUS.ERROR) {
    output.main = <NonIdealState description={error} type="error" />;
  } else if (status === ASYNC_STATUS.SUCCESS) {
    if (!cycleInfos.length || ![...leadsFirstCycle, ...leadsSecondCycle].length) {
      output.main = <NonIdealState type="leads" />;
    } else {
      output.main = cycleInfos.map(cycle => {
        let data;

        if (cycle.status.startsWith('FIRST_CICLE') && leadsFirstCycle.length) {
          data = leadsFirstCycle;
        } else if (cycle.status.startsWith('SECOND_CICLE') && leadsSecondCycle.length) {
          data = leadsSecondCycle;
        }

        if (!data) {
          return null;
        }

        return (
          <div key={cycle.status}>
            <CycleHeading cycle={cycle} />
            <Data data={buildData(data)} />
          </div>
        );
      });
    }
  }

  return (
    <Screen>
      <Container>
        {!!heading && (
          <Heading content={heading} withBorder>
            <Columns>{columns}</Columns>
          </Heading>
        )}
        {output.main}
      </Container>
    </Screen>
  );
}

export default Applications;
