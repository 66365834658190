import { Intera } from '@by-intera/types';
import { API, Auth } from 'aws-amplify';

import { apiName } from '../config';

export async function getApplication(id: string | number): Promise<Intera.Application> {
  return API.get(apiName, `/applications/${id}`, null);
}

export async function getCandidateById(id: string | number): Promise<Intera.Candidate> {
  return API.get(apiName, `/candidates/${id}`, null);
}

export async function getCompanies(): Promise<Intera.Company[]> {
  return API.get(apiName, `/companies/`, null);
}

export async function getCompany(companyId: string | number): Promise<Intera.Company> {
  return API.get(apiName, `/companies/${companyId}`, null);
}

export async function getContracts(companyId: string | number): Promise<Intera.Contract[]> {
  return API.get(apiName, `/contracts/company/${companyId}`, null);
}

export async function getCredits(companyId: string | number): Promise<Intera.CreditsGroupRecord[]> {
  return API.get(apiName, `/credits/company/${companyId}`, null);
}

export async function getJob(jobId: string | number): Promise<Intera.Job> {
  const job = await API.get(apiName, `/jobs/${jobId}`, null);

  return job || {};
}

export async function getJobsByCompanyIdAndCreditsId(
  companyId: string | number,
  creditsId: string | number,
): Promise<Intera.Job[]> {
  return API.get(apiName, `/jobs/all/${companyId}/${creditsId}`, null);
}

export async function getJobs(): Promise<Intera.Job[]> {
  return API.get(apiName, '/jobs/', null);
}

export async function getLeads(id: string | number): Promise<Intera.Application[]> {
  try {
    return await API.get(apiName, `/applications/leads/${id}`, null);
  } catch (error) {
    return [];
  }
}

export async function getUser(): Promise<Intera.CognitoUser> {
  return Auth.currentUserInfo();
}

export function updateApplication(
  id: string,
  payload: Pick<Intera.Application['application'], 'feedbackClient' | 'ratingClient'>,
) {
  return API.patch(apiName, `/applications/${id}`, { body: { application: payload } });
}
