import { AppState } from './types';

const dev = {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://svcs-dev.byintera.com',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_AoxJpKAHR',
    APP_CLIENT_ID: '3evpkj21jnrrmsar4kh53frh25',
    IDENTITY_POOL_ID: 'us-east-1:edaf6778-2313-4965-9c13-77ddcc3298ce',
  },
};

const prod = {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://svcs.byintera.com',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_AoxJpKAHR',
    APP_CLIENT_ID: '3evpkj21jnrrmsar4kh53frh25',
    IDENTITY_POOL_ID: 'us-east-1:edaf6778-2313-4965-9c13-77ddcc3298ce',
  },
};

export const apiName = 'byintera-svcs';

export const appState: AppState = {
  candidate: {
    id: '',
    name: '',
  },
  company: {
    id: null,
    logo: '',
    name: '',
  },
  creditsGroup: {
    id: null,
    name: '',
  },
  isAuthenticated: false,
  isMobile: window.innerWidth < 1024,
  modal: {
    isActive: false,
    type: '',
  },
  position: {
    id: null,
    name: '',
  },
  ui: {
    sidebar: true,
    sidebarVisible: false,
    topBar: true,
  },
  user: null,
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === 'prod' ? prod : dev),
};

export default config;
