import { useParams } from 'react-router-dom';
import { useMount, useSetState } from 'react-use';
import { ASYNC_STATUS, JOB_STATUS } from '@by-intera/constants';
import { AnyObject, Constants, Intera } from '@by-intera/types';
import is from 'is-lite';

import { buildCreditsGroup, buildJob } from 'src/modules/builders';
import { useAppContext } from 'src/modules/context';
import { getCredits, getJobs, getJobsByCompanyIdAndCreditsId } from 'src/modules/requests';

import { appState } from 'src/config';

import Container from 'src/components/Container';
import Data from 'src/components/Data';
import Columns from 'src/components/Data/Columns';
import Heading from 'src/components/Heading';
import Loader from 'src/components/Loader';
import NonIdealState from 'src/components/NonIdealState';
import Screen from 'src/components/Screen';

interface State {
  creditsGroup: Intera.CreditsGroup | null;
  error: string;
  jobs: Intera.Job[];
  status: Constants.AsyncStatus;
}

interface PositionsByStatus {
  active: Intera.Job[];
  inactive: Intera.Job[];
}

function buildData(jobs: Intera.Job[]) {
  if (!is.array(jobs)) {
    return [];
  }

  return jobs.map(position => {
    return buildJob(position);
  });
}

function Positions() {
  const { setState } = useAppContext();
  const { companyId, creditsId } = useParams<{ companyId?: string; creditsId?: string }>();

  const [{ creditsGroup, jobs, status }, setComponentState] = useSetState<State>({
    status: ASYNC_STATUS.IDLE,
    error: '',
    creditsGroup: null,
    jobs: [],
  });

  const load = async () => {
    try {
      setState({
        candidate: appState.candidate,
        position: appState.position,
      });
      setComponentState({ status: ASYNC_STATUS.RUNNING });

      let jobsData: Intera.Job[] = [];
      let selectedCreditsGroup = null;

      if (companyId && creditsId) {
        const creditsIdIdNum = Number(creditsId);
        const companyIdNum = Number(companyId);

        const creditsGroups = await getCredits(companyId);

        selectedCreditsGroup = creditsGroups.find(d => d.creditsGroupId === creditsIdIdNum) || null;

        setState({
          company: {
            id: companyIdNum,
          },
          creditsGroup: {
            id: creditsIdIdNum,
            name: selectedCreditsGroup?.creditsGroup.name || 'Contrato ativo',
          },
        });

        jobsData = await getJobsByCompanyIdAndCreditsId(companyId, creditsId);
      } else {
        setState({
          company: appState.company,
          creditsGroup: {
            ...appState.creditsGroup,
            name: 'Todos os processos',
          },
        });

        jobsData = await getJobs();
      }

      setComponentState({
        creditsGroup: selectedCreditsGroup?.creditsGroup,
        status: ASYNC_STATUS.SUCCESS,
        jobs: jobsData,
      });
    } catch (e: any) {
      setComponentState({ status: ASYNC_STATUS.ERROR, error: e.toString() });
    }
  };

  useMount(load);

  const { columns, heading } = creditsGroup
    ? buildCreditsGroup(creditsGroup, true)
    : ({} as AnyObject);
  const output: AnyObject = {
    main: <Loader origin="route" />,
  };

  if (status === ASYNC_STATUS.ERROR) {
    output.main = <NonIdealState type="error" />;
  }

  if (status === ASYNC_STATUS.SUCCESS) {
    if (!jobs.length) {
      output.main = <NonIdealState type="positions" />;
    } else {
      const { active, inactive } = jobs.reduce<PositionsByStatus>(
        (acc, d) => {
          if (d.status === JOB_STATUS.FINISHED) {
            acc.inactive.push(d);
          } else {
            acc.active.push(d);
          }

          return acc;
        },
        {
          active: [],
          inactive: [],
        },
      );

      if (active.length) {
        output.active = (
          <>
            <Heading
              content="Processos abertos"
              secondary
              style={{ marginTop: 'var(--gutter-lg)' }}
            />
            <Data data={buildData(active)} />
          </>
        );
      }

      if (inactive.length) {
        output.inactive = (
          <>
            <Heading
              content="Processos finalizados"
              secondary
              style={{ marginTop: 'var(--gutter-lg)' }}
            />
            <Data data={buildData(inactive)} />
          </>
        );
      }

      output.main = (
        <>
          {output.active}
          {output.inactive}
        </>
      );
    }
  }

  return (
    <Screen>
      <Container>
        {!!heading && (
          <Heading content={heading} withBorder>
            <Columns>{columns}</Columns>
          </Heading>
        )}
        {output.main}
      </Container>
    </Screen>
  );
}

export default Positions;
