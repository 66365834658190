import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useLocalStorage, useSetState } from 'react-use';
import { AnyObject } from '@by-intera/types';
import produce from 'immer';
import is from 'is-lite';
import { PartialDeep } from 'type-fest';

export function useFormFields<T extends HTMLInputElement>(
  initialState: AnyObject,
): [AnyObject, (event: ChangeEvent<T>) => void, () => void] {
  const [values, setValues] = useSetState(initialState);
  const reset = useRef(() => {
    setValues(initialState);
  });

  return [
    values,
    (event: ChangeEvent<T>) => {
      setValues({
        [event.target.id]: event.target.value,
      });
    },
    reset.current,
  ];
}

export function useLocalStorageState<T extends AnyObject>(
  key: string,
  initialValue: T,
  { serializer = JSON.stringify, deserializer = JSON.parse } = {},
): [state: T, setState: (state: PartialDeep<T>) => void] {
  const { isAuthenticated, user } = initialValue || {};
  const [value, setValue] = useLocalStorage(
    key,
    { isAuthenticated, user },
    {
      raw: false,
      serializer,
      deserializer,
    },
  );
  const [state, set] = useState<T>({ ...initialValue, ...value });

  useEffect(() => {
    setValue({ isAuthenticated: state.isAuthenticated, user: state.user });
  }, [setValue, state]);

  const setState = useCallback((patch: PartialDeep<T> | ((prevState: T) => T)) => {
    set(prevState => {
      const nextState = patch instanceof Function ? patch(prevState) : patch;

      return produce(prevState, draft => {
        // eslint-disable-next-line no-restricted-syntax
        for (const draftKey in draft) {
          if ({}.hasOwnProperty.call(draft, draftKey)) {
            const current = draft[draftKey];
            const next = nextState[draftKey];

            if (is.plainObject(current) && is.plainObject(next)) {
              Object.assign(draft[draftKey], next);
            } else if (!is.undefined(next)) {
              // @ts-ignore
              draft[draftKey] = next;
            }
          }
        }
      });
    });
  }, []);

  return [state, setState];
}
