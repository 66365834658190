export interface FormatDateLocaleOptions {
  locale?: string;
  showTime?: boolean;
}

export interface FormatMoneyOptions {
  decimalChar?: ',' | '.';
  showCents?: boolean;
  symbol?: string;
  thousandsChar?: ',' | '.';
}

export function formatDateLocale(date: string, options: FormatDateLocaleOptions = {}) {
  const { locale = 'pt-br', showTime = false } = options;

  const formatOptions: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };

  if (showTime) {
    formatOptions.hour = '2-digit';
    formatOptions.minute = '2-digit';
  }

  return new Date(date).toLocaleDateString(locale, formatOptions);
}

export function formatMoney(input: number, options: FormatMoneyOptions = {}) {
  const { decimalChar = ',', showCents = false, symbol = 'R$ ', thousandsChar = '.' } = options;

  const isNegative = input < 0;
  const value = Math.abs(input);
  const [amount, cents] = value.toFixed(2).split('.');
  const padStart = amount.length > 3 ? amount.length % 3 : 0;
  const initial = amount.substr(0, padStart);
  const remain = amount.substr(padStart).replace(/(\d{3})(?=\d)/g, `$1${thousandsChar}`);

  let formatted = `${initial ? `${initial}${thousandsChar}` : ''}${remain}`;

  if (cents !== '00' || showCents) {
    formatted += `${decimalChar}${cents}`;
  }

  return `${isNegative ? '-' : ''}${symbol}${formatted}`;
}

export function formatPhoneBR(input: string) {
  const phone = input.replace(/\D/g, '');

  if (phone.length === 8) {
    return phone.replace(/^(\d{4})(\d{4}).*/, '$1-$2');
  }

  if (phone.length === 9) {
    return phone.replace(/^(\d{5})(\d{4}).*/, '$1-$2');
  }

  if (phone.length === 10 || phone.length === 11) {
    return phone.replace(/^(\d{2})(\d{4,5})(\d{4}).*/, '($1) $2-$3');
  }

  // Trying to format international phone numbers without context is impossible...

  return phone;
}
