import { Intera } from '@by-intera/types';

import { formatDateLocale } from './formatters';
import { parseJobStatus } from './parsers';

export function buildContract(contract: Intera.Contract, primary = false) {
  return {
    link: `/positions/${contract.companyId}/${contract.contractId}`,
    heading: primary ? <h1>{contract.name}</h1> : <h3>{contract.name}</h3>,
    columns: [
      <div key="start">
        <h4>Início</h4>
        {contract.dateOnboarding ? formatDateLocale(contract.dateOnboarding) : '--'}
      </div>,
      <div key="end">
        <h4>Fim</h4>
        {contract.dateExpiration ? formatDateLocale(contract.dateExpiration) : '--'}
      </div>,
    ],
  };
}

export function buildCreditsGroup(creditGroup: Intera.CreditsGroup, primary = false) {
  return {
    link: `/positions/${creditGroup.companyId}/${creditGroup.id}`,
    heading: primary ? <h1>{creditGroup.name}</h1> : <h3>{creditGroup.name}</h3>,
    columns: [
      <div key="start">
        <h4>Início</h4>
        {creditGroup.dateOnboarding ? formatDateLocale(creditGroup.dateOnboarding) : '--'}
      </div>,
      <div key="end">
        <h4>Fim</h4>
        {creditGroup.dateExpiration ? formatDateLocale(creditGroup.dateExpiration) : '--'}
      </div>,
      <div key="credits">
        <h4>Créditos</h4>
        {`${creditGroup.creditsUsage.used} / ${creditGroup.creditsTotal}`}
      </div>,
    ],
  };
}

export function buildJob(job: Intera.Job, primary = false) {
  const firstCycle = job.history.find(el => el.value === 'FIRST_CICLE');
  const finished = job.history.find(el => el.value === 'FINISHED');
  let startDate = '--';
  let endDate = '--';

  if (job.start) {
    startDate = formatDateLocale(job.start);
  } else if (firstCycle) {
    startDate = firstCycle.changedAt ? formatDateLocale(firstCycle.changedAt) : '';
  }

  if (job.end) {
    endDate = formatDateLocale(job.end);
  } else if (finished) {
    endDate = finished.changedAt ? formatDateLocale(finished.changedAt) : '';
  }

  const name = job.displayName ? job.displayName : job.role;
  let columns = [
    <div key="creditos">
      <h4>Créditos usados</h4>
      {job.credits ? job.credits : 0}
    </div>,
    <div key="status">
      <h4>Status</h4>
      {parseJobStatus(job.status)}
    </div>,
    <div key="leads">
      <h4>Leads</h4>
      {job.leadsSent || '--'}
    </div>,
  ];

  if (!primary) {
    columns = columns.concat([
      <div key="start">
        <h4>Início</h4>
        {startDate}
      </div>,
      <div key="end">
        <h4>Fim</h4>
        {endDate}
      </div>,
    ]);
  }

  return {
    link: `/applications/${job.backofficeId}`,
    heading: primary ? <h1>{name}</h1> : <h3>{name}</h3>,
    columns,
  };
}
