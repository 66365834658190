import {
  FaClock,
  FaEnvelope,
  FaGithub,
  FaLinkedin,
  FaPaperclip,
  FaPhoneVolume,
} from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useMount, useSetState } from 'react-use';
import { ASYNC_STATUS } from '@by-intera/constants';
import { AnyObject, Constants, Intera, StringOrNull } from '@by-intera/types';

import { useAppContext } from 'src/modules/context';
import { formatDateLocale } from 'src/modules/formatters';
import { parseApplicationRejectStatus, parseApplicationStatus } from 'src/modules/parsers';
import { getApplication, getCandidateById } from 'src/modules/requests';

import Loader from 'src/components/Loader';
import NonIdealState from 'src/components/NonIdealState';
import Phone from 'src/components/Phone';
import ReadMore from 'src/components/ReadMore';
import Screen from 'src/components/Screen';
import StatusIndicator from 'src/components/StatusIndicator';

import styles from './Application.module.scss';
import Feedback from './Feedback';

interface State {
  application: Intera.Application['application'] | null;
  candidate: Intera.Candidate | null;
  cv: StringOrNull;
  date: StringOrNull;
  error: string;
  job: Intera.Application['job'] | null;
  status: Constants.AsyncStatus;
}

function Application() {
  const { id } = useParams<{ id: string }>();
  const {
    setState,
    state: { creditsGroup, isMobile },
  } = useAppContext();
  const [{ application, candidate, cv, date, job, status }, setComponentState] = useSetState<State>(
    {
      application: null,
      candidate: null,
      cv: null,
      date: '',
      error: '',
      job: null,
      status: ASYNC_STATUS.IDLE,
    },
  );

  const load = async () => {
    try {
      setComponentState({ status: ASYNC_STATUS.RUNNING });

      const {
        application: applicationData,
        candidate: { curriculum, idBackoffice: candidateId },
        job: applicationJob,
      } = await getApplication(id);
      const { companyId, companyName, contractId, contractName, id: jobId, role } = applicationJob;

      if (!candidateId) {
        setComponentState({
          status: ASYNC_STATUS.ERROR,
          error: 'Falha ao extrair informações do talento da candidatura',
        });

        return;
      }

      const currentCandidate = await getCandidateById(candidateId);

      setState({
        candidate: {
          id,
          name: currentCandidate.firstName,
        },
        company: {
          id: companyId,
          name: companyName,
        },
        creditsGroup: {
          id: creditsGroup.id || contractId,
          name: creditsGroup.name || contractName || 'Todos os processos',
        },
        position: {
          id: jobId,
          name: role,
        },
      });

      setComponentState({
        application: applicationData,
        cv: curriculum,
        date: applicationData.becameLeadAt,
        job: applicationJob,
        status: ASYNC_STATUS.SUCCESS,
        candidate: currentCandidate,
      });
    } catch (e) {
      setComponentState({ status: ASYNC_STATUS.ERROR });
    }
  };

  useMount(load);

  const output: AnyObject = {
    content: (
      <>
        <Loader display="block" height={14} marginBottom={4} width={230} />
        <Loader display="block" height={14} marginBottom={4} width={235} />
        <Loader display="block" height={14} marginBottom={4} width={240} />
        <Loader display="block" height={14} marginBottom={4} width={235} />
        <Loader display="block" height={14} marginBottom={4} width={245} />
      </>
    ),
    date: <Loader height={14} width={200} />,
    status: <Loader height={14} width={200} />,
    email: <Loader height={14} width={200} />,
    phone: <Loader height={14} width={200} />,
    heading: <Loader height={30} width={300} />,
  };
  const curriculum = candidate?.curriculum || cv;

  if (status === ASYNC_STATUS.SUCCESS) {
    output.confirmedTargetSalary = application?.confirmedTargetSalary;
    output.content = application?.comments || '---';
    output.currentBenefits = application?.currentBenefits;
    output.currentSalary = application?.currentSalary;
    output.date = date ? formatDateLocale(date, { showTime: true }) : '--';
    output.email = <span>{candidate?.email}</span>;
    output.feedback = (
      <Feedback
        comment={application?.feedbackClient}
        id={id}
        isMobile={isMobile}
        rating={application?.ratingClient}
      />
    );
    output.name = (
      <span>
        {candidate?.firstName} {candidate?.lastName}
      </span>
    );

    output.phone = <Phone hideWhatsApp phone={candidate?.phone || ''} />;
    output.status = application?.status
      ? parseApplicationStatus(application.status)
      : 'Desconhecido';

    if (candidate?.linkedinProfileUrl) {
      output.linkedinProfileUrl = (
        <a href={candidate.linkedinProfileUrl} rel="noreferrer" target="_blank">
          {isMobile ? (
            <FaLinkedin className={styles.socialIcons} />
          ) : (
            <button className={`${styles.contentButton} btn btn-primary btn-sm`} type="button">
              Linkedin
            </button>
          )}
        </a>
      );
    }

    if (candidate?.githubProfileUrl) {
      output.github = (
        <a href={candidate.githubProfileUrl} rel="noreferrer" target="_blank">
          {isMobile ? (
            <FaGithub className={styles.socialIcons} type="button" />
          ) : (
            <button className={`${styles.contentButton} btn btn-primary btn-sm`} type="button">
              Github
            </button>
          )}
        </a>
      );
    }

    if (curriculum) {
      output.resume = (
        <a href={curriculum} rel="noreferrer" target="_blank">
          {isMobile ? (
            <FaPaperclip className={styles.socialIcons} type="button" />
          ) : (
            <button className={`${styles.contentButton} btn btn-primary btn-sm`} type="button">
              Currículo
            </button>
          )}
        </a>
      );
    }

    output.heading = (
      <>
        <h1>
          <div className={styles.nameWithStatus}>
            <StatusIndicator
              isActive={!parseApplicationRejectStatus(application?.status)}
              isRejected
            />
            {output.name}
          </div>
          <br />
          <span className={`${styles.statusBadge} badge badge-secondary`}>{job?.role}</span>
        </h1>

        <div>
          <b>Envio:</b> {output.date}
        </div>
        <div>
          <b>Fase:</b> {output.status}
        </div>
        <div className={`pb-2 pt-3 ${styles.contentTitle}`}>Contato</div>
        <div className={`no-gutters ${styles.contentWrapper}`}>
          <div className={`row ${styles.contentBody}`}>
            <div className="col-4 col-md-6">
              <b>Email</b>
              <br />
              {output.email}
            </div>
            <div className="col-4 col-md-6">
              <b>Telefone</b>
              <br />
              {output.phone}
            </div>
          </div>
        </div>
      </>
    );

    output.headingMobile = (
      <>
        {output.feedback}
        <p
          style={{
            textAlign: 'center',
            marginTop: '10px',
          }}
        >
          Lead para a vaga <br />
          <span style={{ color: '#007BFF' }}>{job?.role}</span>
        </p>

        <p style={{ marginTop: '15px', color: '#999', textAlign: 'center' }}>
          <FaClock style={{ marginRight: '10px' }} />
          {output.date}
        </p>
        <h1 style={{ textAlign: 'center' }}>{output.name}</h1>

        <div style={{ textAlign: 'center' }}>
          <div className={styles.nameWithStatus}>
            <StatusIndicator
              isActive={!parseApplicationRejectStatus(application?.status)}
              isRejected
            />
            {output.status}
          </div>
          <br />
          <FaEnvelope style={{ marginRight: '5px' }} /> {output.email} <br />
          <FaPhoneVolume style={{ marginRight: '2px' }} /> {output.phone} <br />
        </div>
      </>
    );
  }

  const hasSalaryOrBenefits = !!(
    output.currentSalary ||
    output.currentBenefits ||
    output.confirmedTargetSalary
  );

  const salaryBenefits = (
    <>
      <div className={`pb-2 pt-3 ${styles.contentTitle}`}>Salários e Benefícios</div>
      <div className={`row no-gutters p-0 ${styles.contentWrapper}`}>
        <div className={`row ${styles.contentBody}`}>
          {output.currentSalary && (
            <div className="col-12 col-md-6">
              <strong>Salário atual</strong> <br /> {output.currentSalary}{' '}
            </div>
          )}
          {output.currentBenefits && (
            <div className="col-12 col-md-6">
              <strong>Pacote de Benefícios</strong> <br /> {output.currentBenefits}{' '}
            </div>
          )}
          {output.confirmedTargetSalary && (
            <div className="col-12 col-md-6">
              <strong>Expectativa Salarial</strong> <br /> {output.confirmedTargetSalary}{' '}
            </div>
          )}
        </div>
      </div>
    </>
  );

  output.social = (
    <div className={isMobile ? styles.links : styles.contentFooter}>
      {output.linkedinProfileUrl}
      {output.github}
      {output.resume}
    </div>
  );

  output.main = (
    <div className={styles.talent}>
      {isMobile ? output.headingMobile : output.heading}
      <div className={`pb-2 pt-3 ${styles.contentTitle}`}>
        Sobre o Talento (comentário da Intera)
      </div>
      <div className={`row no-gutters p-0 ${styles.contentWrapper}`}>
        <div className={styles.contentBody}>
          {status === ASYNC_STATUS.SUCCESS && (
            <ReadMore skip={!isMobile}>{output.content}</ReadMore>
          )}
        </div>
        {!isMobile && output.social}
      </div>
      {hasSalaryOrBenefits && salaryBenefits}

      {isMobile && output.social}
    </div>
  );

  if (status === ASYNC_STATUS.SUCCESS) {
    output.main = (
      <>
        {output.main}
        {!isMobile && output.feedback}
      </>
    );
  }

  if (status === ASYNC_STATUS.ERROR) {
    // eslint-disable-next-line no-multi-assign
    output.main = output.mainMobile = (
      <NonIdealState
        description="Verifique o link de acesso!"
        showLink
        title="Não conseguimos identificar esta candidatura."
        type="error"
      />
    );
  }

  return <Screen className={styles.wrapper}>{output.main}</Screen>;
}

export default Application;
