import React from 'react';
import { Form } from 'react-bootstrap';

import Logo from 'src/components/Logo';

import styles from './FormWrapper.module.scss';

interface Props {
  children: React.ReactNode;
  description?: React.ReactNode;
  error?: React.ReactNode;
  footer?: React.ReactNode;
  hideLogo?: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  title: React.ReactNode;
}

function FormWrapper({ children, description, error, footer, hideLogo, onSubmit, title }: Props) {
  return (
    <div className={styles.wrapper}>
      {!hideLogo && (
        <div className={styles.logo}>
          <Logo width={220} />
        </div>
      )}
      <h1 className={styles.title}>{title}</h1>
      {!!description && <div className={styles.description}>{description}</div>}
      <Form className={styles.form} onSubmit={onSubmit}>
        {children}
      </Form>
      {!!error && <div className={styles.error}>{error}</div>}
      {!!footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
}

export default FormWrapper;
