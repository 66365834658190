import React from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';

import Loader from 'src/components/Loader';

import styles from './LoaderButton.module.scss';

interface Props extends ButtonProps {
  children: React.ReactNode;
  className?: string;
  isLoading: boolean;
}

function LoaderButton({ children, className = '', disabled = false, isLoading, ...rest }: Props) {
  const classes = [styles.loaderButton, className].filter(Boolean).join(' ');

  return (
    <Button className={classes} disabled={disabled || isLoading} {...rest}>
      {children}
      {isLoading && <Loader type="spin" />}
    </Button>
  );
}

export default LoaderButton;
