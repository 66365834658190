import { useMemo } from 'react';
import { Intera } from '@by-intera/types';

import styles from './Credits.module.scss';

interface Props {
  credits: Intera.CreditsGroup[];
}

function CreditsPanel({ credits }: Props) {
  const usage = useMemo(() => {
    return credits.reduce(
      (acc, d) => {
        const processed = d.creditsUsage;

        acc.available += processed.available;
        acc.progress += processed.progress;
        acc.setup += processed.setup;
        acc.finished += processed.finished;
        acc.total += processed.available + processed.used;

        return acc;
      },
      {
        total: 0,
        progress: 0,
        setup: 0,
        available: 0,
        finished: 0,
      },
    );
  }, [credits]);

  return (
    <div className={styles.credits}>
      <div className={styles.credit}>
        <span>Total de Créditos</span>
        <p>{usage.total}</p>
      </div>
      <div className={`${styles.credit} ${styles.creditInProgress}`}>
        <span>Créditos em Andamento</span>
        <p>{usage.progress}</p>
      </div>
      <div className={`${styles.credit} ${styles.creditSetup}`}>
        <span>Créditos em Setup</span>
        <p>{usage.setup}</p>
      </div>
      <div className={`${styles.credit} ${styles.creditAvailable}`}>
        <span>Créditos Disponíveis</span>
        <p>{usage.available}</p>
      </div>
      <div className={styles.credit}>
        <span>Finalizados</span>
        <p>{usage.finished}</p>
      </div>
    </div>
  );
}

export default CreditsPanel;
