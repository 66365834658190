import { createContext, useContext } from 'react';
import { PartialDeep } from 'type-fest';

import { appState } from 'src/config';

import { AppState } from 'src/types';

import { useLocalStorageState } from './hooks';

export const AppContext = createContext({
  state: appState,
  setState: () => undefined,
});
AppContext.displayName = 'AppContext';

export function AppProvider(props: any) {
  const [state, setState] = useLocalStorageState('flow', appState);

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
      }}
      {...props}
    />
  );
}

export function useAppContext(): {
  setState: (
    patch: PartialDeep<AppState> | ((prevState: AppState) => PartialDeep<AppState>),
  ) => void;
  state: AppState;
} {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return context;
}
