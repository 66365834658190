import { useAppContext } from 'src/modules/context';

import NonIdealState from 'src/components/NonIdealState';

import styles from './NotFound.module.scss';

function NotFound() {
  const {
    state: { isAuthenticated },
  } = useAppContext();

  const classes = [styles.wrapper, !isAuthenticated && styles.wrapperPublic]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={classes}>
      <NonIdealState showLink type="notFound" />
    </div>
  );
}

export default NotFound;
