import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { useAppContext } from 'src/modules/context';

function RoutePrivate({ children, ...rest }: React.PropsWithChildren<any>) {
  const { pathname, search } = useLocation();
  const {
    state: { isAuthenticated },
  } = useAppContext();

  return (
    <Route {...rest}>
      {isAuthenticated ? children : <Redirect to={`/login?redirect=${pathname}${search}`} />}
    </Route>
  );
}

export default RoutePrivate;
