import React, { useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useMount, usePrevious, useUnmount, useUpdateEffect } from 'react-use';
import is from 'is-lite';

import styles from './Portal.module.scss';

interface Props {
  children: React.ReactNode;
  closeOnClickOverlay?: boolean;
  closeOnEsc?: boolean;
  hideCloseButton?: boolean;
  hideOverlay?: boolean;
  isActive?: boolean;
  onClose?: (element: HTMLDivElement) => void;
  onOpen?: () => void;
  transitionClassName?: string;
}

function createPortal() {
  const el = document.createElement('div');

  el.classList.add('__portal');

  return el;
}

function Portal(props: Props) {
  const {
    children,
    closeOnClickOverlay = true,
    closeOnEsc = true,
    hideCloseButton = false,
    hideOverlay = false,
    isActive = false,
    onClose,
    onOpen,
  } = props;

  const closePortal = useRef(() => {
    destroyPortal.current();

    if (is.function(onClose)) {
      onClose(portal.current);
    }
  });
  const destroyPortal = useRef(() => {
    if (closeOnEsc) {
      document.removeEventListener('keydown', handleKeyDown);
    }
  });
  const portal = useRef(createPortal());

  const previousIsActive = usePrevious(isActive);
  const previousCloseOnEsc = usePrevious(closeOnEsc);

  const handleKeyDown = useCallback(e => {
    if (e.keyCode === 27) {
      e.stopPropagation();
      closePortal.current();
    }
  }, []);

  useMount(() => {
    document.body.appendChild(portal.current);

    if (isActive && closeOnEsc) {
      document.addEventListener('keydown', handleKeyDown);
    }
  });

  useUnmount(() => {
    destroyPortal.current();
    document.body.removeChild(portal.current);
  });

  const openPortal = useCallback(() => {
    if (is.function(onOpen)) {
      onOpen();
    }

    if (closeOnEsc) {
      document.addEventListener('keydown', handleKeyDown);
    }
  }, [closeOnEsc, handleKeyDown, onOpen]);

  useUpdateEffect(() => {
    const hasChanged = previousIsActive !== isActive;

    if (hasChanged && isActive) {
      openPortal();
    } else if (hasChanged && !isActive) {
      destroyPortal.current();
    }

    if (previousCloseOnEsc !== closeOnEsc) {
      if (closeOnEsc) {
        document.addEventListener('keydown', handleKeyDown);
      } else {
        document.removeEventListener('keydown', handleKeyDown);
      }
    }
  }, [
    closeOnEsc,
    destroyPortal,
    handleKeyDown,
    isActive,
    openPortal,
    previousIsActive,
    previousCloseOnEsc,
  ]);

  const handleClickClose = useCallback(
    e => {
      const el = e.currentTarget;

      if (el.className.includes('overlay') && !closeOnClickOverlay) {
        return;
      }

      closePortal.current();
    },
    [closeOnClickOverlay, closePortal],
  );

  const content = [];

  if (isActive) {
    content.push(children);
  }

  const classes = [styles.wrapper];

  if (isActive) {
    classes.push(styles.wrapperActive);
  }

  return ReactDOM.createPortal(
    <div className={classes.join(' ')}>
      {!hideOverlay && (
        <div className={styles.overlay} onClick={handleClickClose} role="presentation" />
      )}
      {!hideCloseButton && (
        <button
          className={styles.closeButton}
          onClick={handleClickClose}
          title="Close"
          type="button"
        >
          <i className="fas fa-times" />
        </button>
      )}
      <div className={styles.content}>{content}</div>
    </div>,
    portal.current,
  );
}

export default Portal;
