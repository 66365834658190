import React from 'react';
import { Button } from 'react-bootstrap';
import { FallbackProps } from 'react-error-boundary';

import Logo from 'src/components/Logo';

import styles from './ErrorFallback.module.scss';

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div className={styles.wrapper}>
      <Logo />
      <h1>Algo deu errado!</h1>
      <p>{error.message}</p>
      <Button onClick={resetErrorBoundary}>Tente novamente</Button>
    </div>
  );
}

export default ErrorFallback;
