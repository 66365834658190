import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { ASYNC_STATUS } from '@by-intera/constants';
import { AnyObject, Constants } from '@by-intera/types';
import { Auth } from 'aws-amplify';

import { useFormFields } from 'src/modules/hooks';

import FormWrapper from 'src/components/FormWrapper';
import LoaderButton from 'src/components/LoaderButton';

function Signup() {
  const [error, setError] = useState('');
  const [status, setStatus] = useState<Constants.AsyncStatus>(ASYNC_STATUS.IDLE);
  const [fields, handleFieldChange] = useFormFields({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setStatus(ASYNC_STATUS.RUNNING);

    try {
      await Auth.signUp({
        username: fields.email,
        password: fields.password,
      });

      setStatus(ASYNC_STATUS.SUCCESS);
    } catch (e: any) {
      setError(e.message);
      setStatus(ASYNC_STATUS.ERROR);
    }
  };

  const isLoading = status === ASYNC_STATUS.RUNNING;
  const isValid =
    fields.email.length && fields.password.length && fields.password === fields.confirmPassword;
  const output: AnyObject = {
    children: (
      <>
        <Form.Group controlId="email">
          <Form.Control
            autoFocus
            onChange={handleFieldChange}
            placeholder="E-mail"
            required
            type="email"
            value={fields.email}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Control
            onChange={handleFieldChange}
            placeholder="Senha"
            required
            type="password"
            value={fields.password}
          />
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <Form.Control
            onChange={handleFieldChange}
            placeholder="Confirmação de senha"
            required
            type="password"
            value={fields.confirmPassword}
          />
        </Form.Group>
        <Form.Group>
          <LoaderButton block disabled={isLoading && !isValid} isLoading={isLoading} type="submit">
            Enviar
          </LoaderButton>
        </Form.Group>
      </>
    ),
  };

  if (status === ASYNC_STATUS.SUCCESS) {
    output.children = (
      <>
        <h3>Sua conta foi criada!</h3>
        <p>Localize o link de confirmação na caixa de entrada do seu email.</p>
      </>
    );
  }

  return (
    <div className="route-authentication">
      <FormWrapper
        error={error}
        footer={<Link to="/login">{'<'} Voltar para o login</Link>}
        onSubmit={handleSubmit}
        title="Cadastro"
      >
        {output.children}
      </FormWrapper>
    </div>
  );
}

export default Signup;
